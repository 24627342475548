import { serverUrl } from "../../../utils/env-variables-fns";
import { saveAction, getAction } from "../../../utils/store-fns";

let actions = {
  /**
   *
   * @desc Gets all employees in the database
   */
  GETEMPLOYEES: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/human-resources/get-personal`,
        "SET_EMPLOYEES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SAVEEMPLOYEE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/human-resources/save-personal`,
        "ADD_EMPLOYEE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Edits an employee in the database
   */
  EDITPERSONAL: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/human-resources/edit-personal`,
        "EDIT_PERSONAL"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Deletes employees in the database
   */
  DELETEMPLOYEES: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/human-resources/delete-personal`,
        "DELETE_EMPLOYEES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SAVEORGANIZATIONBOARD: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/human-resources/edit-organization-board`,
        "EDIT_ORGANIZATIONBOARD"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETORGANIZATIONBOARD: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/human-resources/get-organization-board`,
        "SET_ORGANIZATIONBOARD"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all bonds in the database
   */
  GETBONDS: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/human-resources/get-bonds`,
        "SET_BONDS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new bonds in the database
   */
  SAVEBOND: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/human-resources/save-bond`,
        "ADD_BOND"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Edits an employee in the database
   */
  EDITBOND: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/human-resources/edit-bond`,
        "EDIT_BOND"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Deletes employees in the database
   */
  DELETBONDS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/human-resources/delete-bond`,
        "DELETE_BONDS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Disables a bond in the database
   */
  DISABLEBOND: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/human-resources/disable-bond`,
        "DISABLE_BOND"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Disables a bond in the database
   */
  ACTIVATEBOND: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/human-resources/activate-bond`,
        "ACTIVATE_BOND"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all comissions from a type from the database
   */
  GETCOMISSIONS: (context, payload) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/human-resources/get-comissions`,
        "SET_COMISSIONS",
        payload
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new incidence in the database
   */
  SAVEINCIDENCE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/human-resources/add-incidence`,
        "ADD_INCIDENCE"
      ).then((response) => {
        context.dispatch("GETCOMISSIONS", { type: 0 });
        context.dispatch("GETCOMISSIONS", { type: 1 });
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all employees inventory in the database
   */
  GETEMPLOYEESINVENTORY: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/human-resources/get-personal-inventory`,
        "SET_EMPLOYEES_INVENTORY"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new employee inventory in the database
   */
  SAVEEMPLOYEEINFORMATION: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/human-resources/save-personal-inventory`,
        "ADD_EMPLOYEE_INVENTORY"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Edits an employee inventory in the database
   */
  EDITEMPLOYEEINFORMATION: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/human-resources/edit-personal-inventory`,
        "EDIT_EMPLOYEE_INVENTORY"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Deletes employees inventory in the database
   */
  DELETEMPLOYEESINVENTORY: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/human-resources/delete-personal-inventory`,
        "DELETE_EMPLOYEES_INVENTORY"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all employees loans in the database
   */
  GETEMPLOYEESLOANS: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/human-resources/get-personal-loans`,
        "SET_EMPLOYEES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new employee loan in the database
   */
  SAVEEMPLOYEELOAN: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/human-resources/save-personal-loan`,
        "EDIT_PERSONAL"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new employee loan payment in the database
   */
  SAVEEMPLOYEELOANPAYMENT: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/human-resources/save-personal-loan-payment`,
        "EDIT_PERSONAL_LOAN_PAYMENTS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Changes a employee loan status in the database
   */
  CHANGEEMPLOYEELOANSTATUS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/human-resources/change-personal-loan-status`,
        "EDIT_PERSONAL_LOAN_STATUS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all employees extra hours in the database
   */
  GETEMPLOYEESEXTRAHOUR: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/human-resources/get-personal-extra-hours`,
        "SET_EMPLOYEES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new employee extra hour in the database
   */
  SAVEEMPLOYEEEXTRAHOURS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/human-resources/save-personal-extra-hours`,
        "EDIT_PERSONAL_EXTRA_HOURS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Deletes a employee extra hour in the database
   */
  DELETEEMPLOYEEEXTRAHOURS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/human-resources/delete-personal-extra-hours`,
        "DELETE_PERSONAL_EXTRA_HOURS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all employees late hours in the database
   */
  GETEMPLOYEESLATEHOUR: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/human-resources/get-personal-late-hours`,
        "SET_EMPLOYEES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new employee late hour in the database
   */
  SAVEEMPLOYEELATEHOURS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/human-resources/save-personal-late-hours`,
        "EDIT_PERSONAL_LATE_HOURS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Deletes an employee late hour in the database
   */
  DELETEEMPLOYEELATEHOURS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/human-resources/delete-personal-late-hours`,
        "DELETE_PERSONAL_LATE_HOURS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all employees missing hours in the database
   */
  GETEMPLOYEESMISSINGHOUR: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/human-resources/get-personal-missing-hours`,
        "SET_EMPLOYEES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new employee missing hour in the database
   */
  SAVEEMPLOYEEMISSINGHOURS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/human-resources/save-personal-missing-hours`,
        "EDIT_PERSONAL_MISSING_HOURS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Deletes an employee missing hour in the database
   */
  DELETEEMPLOYEEMISSINGHOURS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/human-resources/delete-personal-missing-hours`,
        "DELETE_PERSONAL_MISSING_HOURS"
      ).then((response) => {
        resolve(response);
      });
    });
  },

  /**
   *
   * @desc Saves a new vacation in the database
   */
  SAVEVACATION: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/human-resources/save-personal-vacation`,
        "SAVE_VACATION"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all vacations from the database
   */
  GETVACATIONS: (context, payload) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/human-resources/get-personal-vacation`,
        "SET_VACATIONS",
        {
          startDate: payload.startDate,
          endDate: payload.endDate,
        }
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Deletes a vacation in the database
   */
  DELETEVACATION: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/human-resources/delete-personal-vacation`,
        "DELETE_VACATION"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETCURRENTPERSONAL: (context, payload) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/human-resources/get-current-personal`,
        "SET_CURRENT_EMPLOYEE",
        {
          userId: payload.userId,
        }
      ).then((response) => {
        resolve(response);
      });
    });
  },
};

export default actions;
